<template>
	<modal-wrapper @close="$emit('close')" size="small">
		<div class="modal-body">
			<h2 class="settings-headline">
				{{ mode === 'labs' ? 'Neues Labor' : 'Neue Praxis' }}
			</h2>
			<section class="user-edit-form">
				<form @submit.prevent="addAssoc">
					<label for="assoc_name">{{
						mode === 'labs' ? 'Laborname' : 'Praxisname'
					}}</label>
					<input
						type="text"
						id="assoc_name"
						name="assoc_name"
						v-model="$v.assoc_name.$model"
					/>
					<span
						class="has-error"
						v-if="$v.assoc_name.$dirty && !$v.assoc_name.required"
						>Es muss ein
						{{ mode === 'labs' ? 'Laborname' : 'Praxisname' }} angegeben
						sein!</span
					>
					<label for="user_name">Ansprechpartner</label>
					<input
						type="text"
						id="user_name"
						name="user_name"
						v-model="$v.user_name.$model"
					/>
					<span
						class="has-error"
						v-if="$v.user_name.$dirty && !$v.user_name.required"
						>Es muss ein Name angegeben sein!</span
					>
					<label for="user_email">E-Mail Adresse</label>
					<input
						type="email"
						id="user_email"
						name="user_email"
						v-model="$v.user_email.$model"
					/>
					<span
						class="has-error"
						v-if="$v.user_email.$dirty && !$v.user_email.required"
						>Es muss eine E-Mail Adresse angegeben sein!</span
					>
					<label for="password">Passwort</label>
					<input
						type="password"
						id="password"
						name="password"
						v-model="$v.password.$model"
					/>
					<span class="has-error" v-if="!$v.password.minLength"
						>Das neue Passwort muss mindestens 8 Zeichen lang sein!</span
					>
					<span
						class="has-error"
						v-if="$v.password.$dirty && !$v.password.required"
						>Es muss ein Passwort angegeben sein!</span
					>
					<button type="submit" class="button w-button">
						{{ mode === 'labs' ? 'Labor speichern' : 'Praxis speichern' }}
					</button>
					<span class="has-error" v-if="error">
						Es gab einen Fehler, bitte versuchen Sie es erneut.
					</span>
				</form>
			</section>
		</div>
	</modal-wrapper>
</template>

<script>
import {
	required,
	sameAs,
	minLength,
	requiredIf
} from 'vuelidate/lib/validators';
import cryptoMixin from '@/mixins/crypto.mixin';
export default {
	props: ['mode'],
	components: {
		modalWrapper: () => import('../modal-wrapper.vue')
	},
	mixins: [cryptoMixin],
	data() {
		return {
			assoc_name: '',
			user_name: '',
			user_email: '',
			password: '',
			error: false
		};
	},
	validations: {
		assoc_name: { required },
		user_name: { required },
		user_email: { required },
		password: { required, minLength: minLength(8) }
	},
	methods: {
		async addAssoc() {
			this.$v.$touch();
			this.error = false;
			if (!this.$v.$anyError) {
				let url = this.mode === 'labs' ? '/doctor/new_lab' : '/lab/new_doctor';
				const keys = await this.generateKeyPair();
				try {
					const create_response = await this.$api.post(
						url,
						{
							assoc_name: this.assoc_name,
							user_name: this.user_name,
							user_email: this.user_email,
							password: this.password,
							public_key: keys.public_key,
							private_key: keys.private_key,
							creator_id:
								this.mode === 'labs'
									? this.$store.getters.getDoctor.id
									: this.$store.getters.getLab.id
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getUserToken}`
							}
						}
					);
					if (create_response.status === 200) {
						this.$emit('reload');
					} else {
						this.error = true;
					}
				} catch (e) {
					this.error = true;
				}
			}
		},
		async generateKeyPair() {
			const pair = await this.generatePublicPrivateKeyPair();
			const encryptedPrivateKey = await this.encryptPrivateKey(
				this.password,
				pair.privateKey
			);
			return {
				private_key: encryptedPrivateKey,
				public_key: pair.publicKey
			};
		}
	}
};
</script>

<style lang="scss">
.modal-body {
	display: block;
	padding: 1rem;
	display: grid;
	grid-template-rows: 1fr auto;
	.user-edit-form {
		width: 75%;
		padding: 1rem 0 0 1rem;
		form {
			gap: 1rem;
			button {
				width: 50%;
			}
			.has-error {
				text-align: left;
			}
		}
	}
}
</style>
